@import 'common/index';

.root {
  background: color('black');
  position: relative;
  padding: $header-height $grid-desktop-side-margin 0;

  min-height: 720px;
  max-height: 1560px;

  height: 75vh;

  @include media-height(960px) {
    height: calc(100vh - 108px);
  }

  @include media(mobile) {
    padding: 118px $grid-mobile-side-margin 0;
    min-height: 430px + $header-height-mobile;
    height: 67.5vh;
  }
}

.container {
  height: 100%;
  min-height: 100%;
  max-width: 940px + $grid-desktop-side-margin * 2;
  margin: 0 auto;
  position: relative;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 45px;

  @include media(mobile) {
    padding-bottom: 75px;
  }
}

.background-layer {
  position: absolute;
  z-index: 1;
  top: 92px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 496px;
  overflow: hidden;

  @include media(mobile) {
    max-width: 50%;
    min-width: 190px;
  }
}

.ray-holder {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes ray-transition {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ray-layer {
  z-index: 1;
  position: absolute;
  top: -1px;
  bottom: -1px;
  width: 100%;
  background: transparent repeat-y top center / 100% 1px;

  @include media(mobile) {
    background-position: top center;
  }
}

.ray-layer-first {
  background-image: url('../../../common/assets/images/ray/ray-layer-1.jpg');
}

.ray-layer-second {
  background-image: url('../../../common/assets/images/ray/ray-layer-2.jpg');
  animation: ray-transition infinite alternate 2s 0s both cubic-bezier(0.68, 0.22, 0.3, 0.78);
}

.ray-layer-third {
  background-image: url('../../../common/assets/images/ray/ray-layer-3.jpg');
  animation: ray-transition infinite alternate 3s 0s both cubic-bezier(0.68, 0.22, 0.3, 0.78);
}

.box-top-image {
  position: absolute;
  z-index: 2;
  bottom: -7px;
  height: 78px;
  width: 100%;
  background: transparent no-repeat url('../../../common/assets/images/top-tv.png') top center / cover;
  transform: translateZ(0);

  @include media(mobile) {
    background-size: contain;
    background-position: bottom center;
  }
}

.foreground-layer {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
}

.logo {
  width: 100%;
  max-width: 700px;
  margin: -20px auto -32px;

  @include media(mobile) {
    max-width: 400px;
    margin: -43px auto -48px;
  }
}

.subtitle {
  font-size: 24px;
  letter-spacing: -0.02em;
  max-width: 400px;
  margin: 0 auto 16px;
  text-align: center;

  @include media(mobile) {
    font-size: 20px;
    margin: 0 auto 10px;
    max-width: 360px;
  }
}

.description {
  text-transform: uppercase;
  margin: 0 auto;
  max-width: 140px;
  @include font-weight(500);
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.05em;
}

.ces-wrapper {
  position: relative;
  width: 116px;
  margin: 36px auto 0;

  @include media(mobile) {
    width: 82px;
    margin-top: 35px;
  }
}

.ces-platter {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  background: rgba(95, 95, 95, 0.45);
  backdrop-filter: blur(24px);
  z-index: 0;

  @include media(mobile) {
    top: 9px;
  }
}

.ces-text {
  cursor: default;
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 1;
  margin: 0;

  @include media(mobile) {
    font-size: 9px;
  }
}

.ces-image {
  position: relative;
  z-index: 1;
  height: 70px;

  @include media(mobile) {
    height: 52px;
  }
}
