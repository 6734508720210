@import 'common/index';

html {
  box-sizing: border-box;
  background: color('black');
  @include font-text();
  font-size: 16px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
  color: color('white');
  min-width: breakpoint(mobile-min);
}

body {
  margin: 0;

  &.loading {
    height: 100vh;
    overflow: hidden;
  }
}

::selection {
  text-shadow: none;
  background: color('black');
  color: color('white');
}

template,
.hidden,
[hidden] {
  display: none !important; /* stylelint-disable declaration-no-important */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong {
  @include font-weight(500);
}

input {
  &[type='search' i]::-webkit-search-cancel-button,
  &[type='number' i]::-webkit-inner-spin-button {
    pointer-events: none;
    display: none;
  }
}
