@import 'common/index';

.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  background: color('dark-grey');
  padding: 0 $grid-desktop-side-margin;
  z-index: 2;
  min-width: breakpoint(mobile-min);

  @include media(mobile) {
    height: $header-height-mobile;
    background: transparent;
  }
}
