@import 'common/index';

@keyframes :global(ray-transition-1) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes :global(ray-transition-2) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.root {
  position: relative;
}

.layer {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent repeat-y top center / 100% 1px;

  @include media(mobile) {
    background-position: top center;
  }
}

:global {
  :local(.layer-first) {
    background-image: url('/common/assets/images/ray/ray-layer-1.jpg');
  }

  :local(.layer-second) {
    background-image: url('/common/assets/images/ray/ray-layer-2.jpg');
    animation: ray-transition-1 infinite alternate 2s 0s both cubic-bezier(0.68, 0.22, 0.3, 0.78);
  }

  :local(.layer-third) {
    background-image: url('/common/assets/images/ray/ray-layer-3.jpg');
    animation: ray-transition-2 infinite alternate 3s 0s both cubic-bezier(0.68, 0.22, 0.3, 0.78);
  }
}

.gradient {
  z-index: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: 45%;
  background: get-black-gradient();
}
