$grid-desktop-columns: 12;
$grid-desktop-gutter: 16px;
$grid-desktop-width: 840px;
$grid-desktop-side-margin: 60px;
$grid-desktop-min-width: $grid-desktop-width + $grid-desktop-side-margin * 2;

$grid-mobile-columns: 12;
$grid-mobile-side-margin: 20px;
$grid-mobile-gutter: 16px;
$grid-mobile-width: breakpoint(mobile);
$grid-mobile-min-width: $grid-mobile-width + $grid-mobile-side-margin * 2;
$grid-mobile-breakpoint: breakpoint(mobile);

@function col-width($i, $columns) {
  @return 100% / $columns * $i;
}

@mixin col-props($count, $columns, $gutter, $center) {
  $halfGutter: $gutter/2;

  @if ($count == $columns) {
    $halfGutter: 0;
  }

  box-sizing: border-box;
  width: col-width($count, $columns);
  padding-left: $halfGutter;
  padding-right: $halfGutter;

  @if ($center == true) {
    &,
    &:first-child:last-child {
      margin-left: auto;
      margin-right: auto;
    }
  } @else {
    &:first-child {
      margin-left: -$halfGutter;
    }

    &:last-child {
      margin-right: -$halfGutter;
    }

    &:first-child:last-child {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@mixin columns-loop($name, $columns, $gutter, $center) {
  @for $i from 0 through $columns {
    &-#{$name}-#{$i} {
      @include col-props($i, $columns, $gutter, $center);
    }

    //&-#{$name}-shift-#{$i} {
    //  margin-left: col-width($i, $columns);
    //}
  }
}

@mixin grid() {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;

  margin-left: auto;
  margin-right: auto;

  max-width: $grid-desktop-width;

  @media screen and (max-width: $grid-desktop-min-width) {
    margin-left: $grid-desktop-side-margin;
    margin-right: $grid-desktop-side-margin;
  }

  @media screen and (max-width: $grid-mobile-width) {
    margin-left: $grid-mobile-side-margin;
    margin-right: $grid-mobile-side-margin;
  }
}

@mixin grid-col($count: 12, $center: false) {
  @include col-props($count, $grid-desktop-columns, $grid-desktop-gutter, $center);
}

@mixin grid-col-mobile($count: 12, $center: false) {
  @media screen and (max-width: $grid-mobile-width) {
    @include col-props($count, $grid-mobile-columns, $grid-mobile-gutter, $center);
  }
}

@mixin grid-offset($count: 0) {
  &,
  &:first-child,
  &:last-child,
  &:first-child:last-child {
    margin-left: col-width($count, $grid-desktop-columns);
  }
}

@mixin grid-offset-mobile($count: 0) {
  &,
  &:first-child,
  &:last-child,
  &:first-child:last-child {
    @media screen and (max-width: $grid-mobile-width) {
      margin-left: col-width($count, $grid-desktop-columns);
    }
  }
}
