@import 'common/index';

$tv-img-ratio: calc(100vw / 1.538);

.root {
  position: relative;

  @include media(mobile-min) {
    padding: 0 15px;
  }
}

.spacer-top {
  height: 80vw;
  z-index: 1;

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    z-index: 2;
    height: 40vh;
    content: '';
    background: get-black-gradient();
  }

  @include media(mobile) {
    &::before {
      height: 20vh;
    }
  }

  @include media(mobile-sm) {
    height: 80vw;
  }
}

.container {
  position: absolute;
  height: $tv-img-ratio;
  width: 100vw;
  bottom: -20vw;
  margin: 0 auto;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-container {
  position: relative;
  width: inherit;
  height: 80px;

  @include media(mobile) {
    height: 32px;
    margin-bottom: 5px;
  }
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.price {
  margin: 0 0 29px;
  padding: 0;

  @include font-display(true);
  @include font-weight(500);
  font-size: 70px;
  line-height: 100%;

  @include media(mobile) {
    font-size: 26px;
    margin: 0 auto 10px;
    max-width: 360px;
  }
}

.tv-box {
  box-sizing: content-box;
  display: block;
  width: 100vw;
  height: $tv-img-ratio;

  &::after {
    position: absolute;
    top: $tv-img-ratio;
    height: 35vh;
    width: 100vw;
    content: '';
    background: color('black');
  }
}

.tv-box-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  @include media(mobile-sm) {
    transform: scale(1.4);
  }

  @include media(mobile-min) {
    transform: scale(1.6);
  }
}
