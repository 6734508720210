@import 'common/index';

.stage {
  background-image: url('./background.jpg');

  @include media(mobile-md) {
    background-image: url('./background-mobile-md.jpg');
  }
}

.person-dancing-container {
  position: absolute;
  z-index: 3;
  right: 50%;
  height: 150%;
  top: 0;
  bottom: 0;
  margin: auto -80px auto 0;

  @include media(mobile) {
    margin-left: -20%;
    height: 120%;
  }
}

.person-dancing {
  height: 100%;
  display: block;

  @include media(mobile) {
    filter: brightness(0.75);
  }
}
