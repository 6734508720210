@mixin transition($properties, $duration: $transition-duration, $delay: $transition-delay, $timing-function: $transition-timing-function) {
  transition-timing-function: $timing-function;
  transition-delay: $delay;
  transition-duration: $duration;

  transition-property: join($properties, (), $separator: comma);
}

@mixin font-display($font-family-only: false) {
  font-family: $font-family-display, sans-serif;

  @if ($font-family-only == false) {
    letter-spacing: -0.01em;
  }
}

@mixin font-text($font-family-only: false) {
  font-family: $font-family-text, sans-serif;

  @if ($font-family-only == false) {
    letter-spacing: -0.02em;
  }
}

@mixin font-weight($weight: normal) {
  font-weight: $weight;
}

@mixin media($size) {
  @media only screen and (max-width: breakpoint($size)) {
    @content;
  }
}

@mixin media-height($value) {
  @media only screen and (max-height: $value) {
    @content;
  }
}

@mixin debug {
  &,
  & * {
    &,
    &::before,
    &::after {
      outline: 0.5px dashed #c0f;
    }
  }
}

@mixin ray-width($width-property: width) {
  #{$width-property}: 840px;

  @include media(tablet) {
    #{$width-property}: 650px;
  }

  @include media(mobile) {
    #{$width-property}: 460px;
  }

  @include media(mobile-md) {
    #{$width-property}: 400px;
  }

  @include media(mobile-sm) {
    #{$width-property}: 320px;
  }
}

@mixin ray-size-content() {
  @include ray-width(max-width);
  padding-left: 60px;

  @include media(tablet) {
    padding-left: 50px;
  }

  @include media(mobile) {
    padding-left: 40px;
  }

  @include media(mobile-md) {
    padding-left: 25px;
  }

  @include media(mobile-sm) {
    padding-left: 0;
  }
}

@mixin ray-size-content-title() {
  font-size: 74px;
  line-height: 0.9;

  @include media(tablet) {
    font-size: 56px;
    margin-bottom: 15px;
  }

  @include media(mobile) {
    font-size: 40px;
    line-height: 0.95;
    margin-bottom: 10px;
  }
}

@mixin ray-size-content-subtitle() {
  font-size: 23px;
  line-height: 1.15;

  @include media(tablet) {
    font-size: 17px;
  }

  @include media(mobile) {
    font-size: 16px;
    line-height: 1.25;
  }
}
