$font-family-text: 'SB Sans Text';
$font-family-display: 'SB Sans Display';
$fonts_url: '/common/assets/fonts/';

$colors: (
  'black': #000,
  'white': #fff,
  'dark-grey': #121212,
  'dark-gray': #121212,
  'gray': #222,
  'grey': #222,
  'blue': #2871f6,
  'blue-darker': #1663f0,
);

$breakpoints: (
  'desktop-max': 2560px,
  'desktop-lg': 2560px,
  'desktop': 1440px,
  'desktop-md': 1336px,
  'desktop-sm': 1280px,
  'tablet': 1024px,
  'tablet-md': 960px,
  'mobile': 768px,
  'mobile-md': 640px,
  'mobile-sm': 480px,
  'mobile-min': 360px,
);

$transition-duration: 0.15s;
$transition-delay: 0s;
$transition-timing-function: ease-in-out;

$header-height: 78px;
$header-height-mobile: 45px;
