@import 'common/index';

.root {
  @include font-weight();
  @include font-text;
  font-size: 20px;
  line-height: 1.3;
}

.withoutMargin {
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}
