@font-face {
  font-family: $font-family-display;
  src: url('#{$fonts_url}SBSansDisplay-Regular.eot');
  src: url('#{$fonts_url}SBSansDisplay-Regular.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansDisplay-Regular.woff2') format('woff2'),
    url('#{$fonts_url}SBSansDisplay-Regular.woff') format('woff'), url('#{$fonts_url}SBSansDisplay-Regular.ttf') format('truetype'),
    url('#{$fonts_url}SBSansDisplay-Regular.svg#SBSansDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-family-display;
  src: url('#{$fonts_url}SBSansDisplay-Thin.eot');
  src: url('#{$fonts_url}SBSansDisplay-Thin.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansDisplay-Thin.woff2') format('woff2'),
    url('#{$fonts_url}SBSansDisplay-Thin.woff') format('woff'), url('#{$fonts_url}SBSansDisplay-Thin.ttf') format('truetype'),
    url('#{$fonts_url}SBSansDisplay-Thin.svg#SBSansDisplay-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: $font-family-display;
  src: url('#{$fonts_url}SBSansDisplay-Light.eot');
  src: url('#{$fonts_url}SBSansDisplay-Light.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansDisplay-Light.woff2') format('woff2'),
    url('#{$fonts_url}SBSansDisplay-Light.woff') format('woff'), url('#{$fonts_url}SBSansDisplay-Light.ttf') format('truetype'),
    url('#{$fonts_url}SBSansDisplay-Light.svg#SBSansDisplay-Light') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: $font-family-display;
  src: url('#{$fonts_url}SBSansDisplay-SemiBold.eot');
  src: url('#{$fonts_url}SBSansDisplay-SemiBold.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansDisplay-SemiBold.woff2') format('woff2'),
    url('#{$fonts_url}SBSansDisplay-SemiBold.woff') format('woff'), url('#{$fonts_url}SBSansDisplay-SemiBold.ttf') format('truetype'),
    url('#{$fonts_url}SBSansDisplay-SemiBold.svg#SBSansDisplay-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $font-family-display;
  src: url('#{$fonts_url}SBSansDisplay-Bold.eot');
  src: url('#{$fonts_url}SBSansDisplay-Bold.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansDisplay-Bold.woff2') format('woff2'),
    url('#{$fonts_url}SBSansDisplay-Bold.woff') format('woff'), url('#{$fonts_url}SBSansDisplay-Bold.ttf') format('truetype'),
    url('#{$fonts_url}SBSansDisplay-Bold.svg#SBSansDisplay-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Regular.eot');
  src: url('#{$fonts_url}SBSansText-Regular.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Regular.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Regular.woff') format('woff'), url('#{$fonts_url}SBSansText-Regular.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Regular.svg#SBSansText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Italic.eot');
  src: url('#{$fonts_url}SBSansText-Italic.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Italic.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Italic.woff') format('woff'), url('#{$fonts_url}SBSansText-Italic.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Italic.svg#SBSansText-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Thin.eot');
  src: url('#{$fonts_url}SBSansText-Thin.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Thin.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Thin.woff') format('woff'), url('#{$fonts_url}SBSansText-Thin.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Thin.svg#SBSansText-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Light.eot');
  src: url('#{$fonts_url}SBSansText-Light.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Light.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Light.woff') format('woff'), url('#{$fonts_url}SBSansText-Light.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Light.svg#SBSansText-Light') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-LightItalic.eot');
  src: url('#{$fonts_url}SBSansText-LightItalic.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-LightItalic.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-LightItalic.woff') format('woff'), url('#{$fonts_url}SBSansText-LightItalic.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-LightItalic.svg#SBSansText-LightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Medium.eot');
  src: url('#{$fonts_url}SBSansText-Medium.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Medium.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Medium.woff') format('woff'), url('#{$fonts_url}SBSansText-Medium.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Medium.svg#SBSansText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-SemiBold.eot');
  src: url('#{$fonts_url}SBSansText-SemiBold.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-SemiBold.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-SemiBold.woff') format('woff'), url('#{$fonts_url}SBSansText-SemiBold.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-SemiBold.svg#SBSansText-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-SemiBoldItalic.eot');
  src: url('#{$fonts_url}SBSansText-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-SemiBoldItalic.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-SemiBoldItalic.woff') format('woff'), url('#{$fonts_url}SBSansText-SemiBoldItalic.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-SemiBoldItalic.svg#SBSansText-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Bold.eot');
  src: url('#{$fonts_url}SBSansText-Bold.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Bold.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Bold.woff') format('woff'), url('#{$fonts_url}SBSansText-Bold.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Bold.svg#SBSansText-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-BoldItalic.eot');
  src: url('#{$fonts_url}SBSansText-BoldItalic.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-BoldItalic.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-BoldItalic.woff') format('woff'), url('#{$fonts_url}SBSansText-BoldItalic.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-BoldItalic.svg#SBSansText-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: $font-family-text;
  src: url('#{$fonts_url}SBSansText-Heavy.eot');
  src: url('#{$fonts_url}SBSansText-Heavy.eot?#iefix') format('embedded-opentype'), url('#{$fonts_url}SBSansText-Heavy.woff2') format('woff2'),
    url('#{$fonts_url}SBSansText-Heavy.woff') format('woff'), url('#{$fonts_url}SBSansText-Heavy.ttf') format('truetype'),
    url('#{$fonts_url}SBSansText-Heavy.svg#SBSansText-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
}
