@import 'common/index';

.root {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //pointer-events: none;

  @include media(tablet) {
    display: none;
  }
}

.image-wrapper {
  //background: rgba(0, 0, 255, 0.25);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 70px;
  right: 70px;
  //margin: auto;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  transform: translate3d(0, 0, 0);

  @include ray-width(min-width);
}

.image-sub-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate3d(0, 0, 0);
}

.image {
  position: relative;
  display: block;
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
}

.image-shade {
  position: absolute;
  left: 0;
  right: 0;
  height: 800vh;
  background: #000;
  z-index: 0;
}

.camera-benefits-image-shade {
  top: 330px;
  transform: skewY(13deg);
}

.microphone-benefits-image {
  max-width: 113%;
  left: -10px;
}

.microphone-benefits-image-shade {
  top: 210px;
  transform: skewY(161deg);
}

.privacy-benefits-image {
  max-width: 103%;
  left: -10px;
}

.privacy-benefits-image-shade {
  top: 150px;
  transform: skewY(168deg);
}

.privacy-benefits-image-sub-wrapper {
  top: 25%;
}

.controls-benefits-image {
  max-width: 103%;
}

.controls-benefits-image-shade {
  top: 80px;
}
