@import 'common/index';

.root {
  position: relative;
}

.spacer-top {
  height: 120vh;
}

.spacer-bottom {
  height: 80vh;
}

.container {
  position: relative;
}

.title {
  @include grid();
}

.title-text {
  @include grid-col(12);
  @include font-display();
  @include font-weight(500);
  font-size: 130px;
  line-height: 0.8;
  margin: 0;

  @include grid-col-mobile(12);

  @include media(mobile) {
    font-size: 42px;
    line-height: 0.95;
    max-width: 250px;
  }
}

.subtitle {
  @include grid();
  margin: 35px auto;
  flex-wrap: nowrap;
  align-items: center;

  @include media(mobile) {
    margin-top: 15px;
    margin-bottom: 25px;
  }
}

.subtitle-text {
  margin: 0 25px 0 0;

  @include grid-col-mobile(6);
  @include media(mobile) {
    margin-right: 0;
  }
}

.subtitle-text-value {
  margin: 0;
  max-width: 300px;
  font-size: 37px;
  line-height: 1.15;

  @include media(mobile) {
    max-width: none;
    font-size: 16px;
    line-height: 1.25;
  }
}

.subtitle-angle {
  @include font-display();
  @include font-weight(300);
  font-size: 130px;
  line-height: 0.8;

  @include grid-col-mobile(6);
  @include media(mobile) {
    font-size: 70px;
    line-height: 0.85;
  }
}

.subtitle-angle-value {
  @supports (-webkit-background-clip: text) {
    background: linear-gradient(90deg, #fff -0.24%, #fff 0.92%, #fff 34.6%, #0ac7d0 82.68%, #0ac7d0 109.86%, #0ac7d0 123.8%);

    // stylelint-disable-next-line
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include media(mobile) {
    padding-left: 10px;
  }
}

.benefits {
  list-style: none;
  padding: 0;
  margin: 0;
  @include grid();

  & + & {
    margin-top: 70px;

    @include media(mobile) {
      margin-top: 18px;
    }
  }
}

.benefit {
  position: relative;
  @include grid-col(6);
  @include grid-col-mobile(12);
  font-size: 20px;
  line-height: 1.3;

  &:not(:last-of-type) {
    padding-right: 25px;
  }

  @include media(mobile) {
    &:not(:last-of-type) {
      padding-right: 0;
    }

    & + & {
      margin-top: 18px;
    }

    padding-left: 58px;
    font-size: 16px;
    line-height: 1.25;
  }
}

.benefit-icon {
  display: block;
  width: 52px;
  height: 52px;

  @include media(mobile) {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.benefit-header {
  @include font-weight(500);
  display: block;
  margin: 6px 0 9px;
  @include font-weight(600);
  line-height: 1.34;

  @include media(mobile) {
    margin: 0 0 4px;
  }
}
