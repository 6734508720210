@import 'common/index';

.app-holder {
  position: relative;
  overflow: hidden;
}

.main {
  width: 100%;
  position: relative;
  z-index: 1;

  @at-root :global(.loading) & {
    height: 100vh;
    pointer-events: none;
  }
}

.sections-wrapper {
  //pointer-events: none;
  //opacity: 0.85;
  //visibility: hidden;
  //position: relative;
  //z-index: 1;
}
