@import 'common/index';

.root {
  position: relative;
  background: color('black');
}

.spacer-top {
  height: 120vh;
}

.spacer-bottom {
  height: 120vh;
}

.container {
  position: relative;
  padding-top: 35px;

  @include media(mobile-sm) {
    padding-top: 10px;
  }
}

.benefits {
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  list-style: none;
}

.benefit {
  position: relative;
  @include grid-col(4);
  @include grid-col-mobile(12);
  width: 90%;
  margin-bottom: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 20px;
  line-height: 1.3;
  text-align: center;

  &:last-child {
    margin-bottom: 16px;
  }

  @include media(mobile) {
    max-width: 95%;
    margin-bottom: 41px;

    font-size: 16px;
    line-height: 1.25;
  }
}

.benefit-icon {
  display: block;
  width: 90px;
  height: 90px;
  margin-bottom: 31px;

  @include media(mobile) {
    width: 54px;
    height: 54px;
    margin-bottom: 16px;
  }
}

.benefit-dialog {
  margin: 0 0 16px;
  padding: 0;
  font-size: 114px;
  line-height: 0.9;
  @include font-display();
  @include font-weight(500);

  &::first-letter {
    margin-left: -1.8ch;
  }

  @include media(tablet) {
    font-size: 93px;
    line-height: 0.95;
  }

  @include media(mobile) {
    font-size: 36px;
    margin-bottom: 10px;
    line-height: 0.95;
  }
}

.benefit-description {
  width: 55%;

  font-size: 31px;
  line-height: 1.15;
  letter-spacing: -0.02em;
  margin: 15px 0 0;

  @include media(tablet) {
    font-size: 20px;
  }

  @include media(mobile) {
    margin: 0;
    font-size: 16px;
    width: 70%;
  }
}
