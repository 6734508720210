@import 'common/index';

.root {
  box-sizing: border-box;
  position: relative;
  padding-top: 32px;
  background: color('black');
  z-index: 3;
}

.container {
  position: relative;
  padding: 0 55px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 32px;

  @include media(mobile) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media(mobile-sm) {
    padding: 0 20px;
    padding-bottom: 32px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }
}

.menu {
  list-style: none;
  display: flex;

  @include media(mobile) {
    order: 0;
    margin-bottom: 24px;
  }
}

.menu-col {
  @include media(mobile) {
    padding: 0;

    &:first-child {
      margin-right: 48px;
    }
  }
}

.menu-item {
  list-style: none;
  margin-bottom: 15px;
  @include font-display(true);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  @include media(mobile) {
    margin-bottom: 24px;
  }
}

.menu-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.menu-link:hover {
  color: rgb(0, 110, 255);
  cursor: pointer;
}

.contacts {
  max-width: 290px;
  font-family: SB Sans Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.phone {
  @include font-weight(600);
  font-size: 17px;
  line-height: 22px;
}

.contacts-text {
  margin: 0 0 24px;
  color: rgba(255, 255, 255, 0.7);
}

.policy {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  margin: 0;

  @include media(mobile) {
    order: 3;
    padding: 0;
    line-height: 32px;
  }
}

.policy-item {
  list-style: none;
}

.policy-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-right: 16px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.policy-link:hover {
  color: rgb(0, 110, 255);
  cursor: pointer;
}

.icons {
  @include media(mobile) {
    order: 2;
    margin-bottom: 32px;
  }
}

.icon {
  margin-right: 16px;
  opacity: 0.7;
}
