@import 'common/index';

.section.section {
  overflow: hidden;
  height: 0;
  padding-bottom: 1400px;

  background: linear-gradient(to bottom, transparent 60%, #060606 80%);

  @include media(desktop-max) {
    padding-bottom: 1350px;
  }

  @include media(tablet) {
    padding-bottom: 1277px;
  }

  @include media(mobile) {
    padding-bottom: 1125px;
  }

  @include media(mobile-md) {
    padding-bottom: 1175px;
  }

  @include media(mobile-sm) {
    padding-bottom: 1055px;
  }

  .stage {
    background-image: url('./background.png');

    background-position: center -278px;

    @include media(tablet) {
      background-position-y: -200px;
      background-size: auto 2034px;
    }
    @include media(mobile) {
      background-position-y: -111px;
      background-size: auto 1441px;
    }
    @include media(mobile-md) {
      background-size: auto 1253px;
      background-position-y: -97px;
      background-image: url('./background-mobile-md.png');
    }
    @include media(mobile-sm) {
      background-size: auto 1003px;
      background-position-y: -58px;
    }
  }

  .box-top-image {
    top: 83px;

    @include media(tablet) {
      height: 20px;
      top: 90px;
    }

    @include media(mobile) {
      top: 90px;
    }

    @include media(mobile-md) {
      height: 12px;
      top: 82px;
    }

    @include media(mobile-sm) {
      height: 10px;
      top: 85px;
    }
  }

  .foreground-layer {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  .customers {
    pointer-events: none;
    background: url('./customers.png') no-repeat center bottom / auto 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 847px; // 77%;
    width: 100%;
    z-index: 2;

    @include media(mobile) {
      height: 62%;
    }

    @include media(mobile-md) {
      background-position: center top;
      background-size: auto 375px;
      height: 62%;
      bottom: 230px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 420px;
        background: #060606;
      }
    }
  }
}

.services {
  margin: 40px 0 0;
  max-width: 700px;
}

.services-list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: -10px;
  padding: 0;

  @include media(mobile) {
    margin-top: 20px;
  }
}

.service-item {
  color: #fff;
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 10px;

  @include media(mobile) {
    padding: 5px;
  }
}

.service-item-content {
  flex: 1 1 100%;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 30px;
  padding: 18px;

  @include media(mobile) {
    padding: 10px;
    border-radius: 20px;
  }
}

.service-logo {
  margin-right: auto;
  max-width: 100%;
}

.service-description.service-description {
  @include media(tablet) {
    font-size: 16px;
    line-height: 1.25;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.okko {
  .service-item-content {
    background: #5020ab;
  }
}

.smotreshka {
  .service-item-content {
    background: #fdcf59;
    color: #000;
  }
}

.sberplay {
  .service-item-content {
    background: #117738 linear-gradient(217.82deg, #13b150 6.53%, #117738 93.4%);
  }
}

.sberzvuk {
  .service-item-content {
    background: #e757ff radial-gradient(86.23% 94.68% at 126.05% 16.38%, #18d4ff 0%, #e757ff 100%);
  }
}

.wellbell {
  .service-item-content {
    background: #404040;
  }
}

.okko,
.smotreshka {
  flex-basis: 50%;

  .service-logo {
    height: 36px;
    margin-bottom: 12px;
  }

  .service-description {
    margin-bottom: 31px;
  }
}

.sberplay,
.sberzvuk,
.wellbell {
  flex-basis: 33.333%;

  .service-logo {
    height: 23px;
    margin: 5px auto 8px 0;
  }

  .service-description {
    margin-bottom: 16px;
  }
}

@include media(tablet) {
  .okko,
  .sberplay,
  .sberzvuk,
  .wellbell {
    flex-basis: 50%;

    .service-logo {
      height: 23px;
      margin: 0 auto 8px 0;
    }
  }

  .sberzvuk {
    order: -1;

    .service-logo {
      max-width: 115px;
      object-fit: contain;
    }
  }

  .sberplay {
    .service-logo {
      max-width: 105px;
      object-fit: contain;
    }
  }

  .wellbell {
    .service-logo {
      max-width: 82px;
      object-fit: contain;
    }
  }

  .smotreshka {
    flex-basis: 100%;

    .service-logo {
      height: 32px;
    }
  }
}

.promo-legal-link {
  margin-top: 30px;
  color: rgba(255, 2555, 255, 0.3);
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  padding: 5px;
  margin-left: -5px;
  @include transition(color);

  &:hover {
    color: rgba(255, 2555, 255, 1);
  }
}

.gift {
  max-width: 100%;
  border-radius: 37px;
  padding: 9px 16px;
  margin-top: auto;
  margin-right: auto;
  display: inline-flex;
  flex-flow: row nowrap;
  color: inherit;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  svg {
    margin-right: 10px;
  }

  &::after {
    content: '';
    border: 1px solid currentColor;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    opacity: 0.2;
    pointer-events: none;
  }

  @include media(tablet) {
    font-size: 11px;

    padding: 5px 9px;
    align-items: center;

    svg {
      margin-right: 5px;
      width: 13px;
    }
  }
}

.sup {
  font-size: 0.75rem;
}
