@import 'common/index';

.stage {
  background-image: url('./background.jpg');

  @include media(mobile-md) {
    background-image: url('./background-mobile-md.jpg');
  }
}

.title {
  max-width: 400px;

  @include media(tablet) {
    max-width: 250px;
  }

  @include media(mobile) {
    max-width: 180px;
  }
}

.subtitle {
  max-width: 350px;

  @include media(tablet) {
    max-width: 250px;
  }

  @include media(mobile) {
    max-width: 180px;
  }
}

.person-training-container {
  position: absolute;
  z-index: 3;
  left: -222px;
  height: 150%;
  top: 80px;
  bottom: 0;
  margin: auto 50%;

  @include media(tablet) {
    top: 0;
  }

  @include media(mobile) {
    top: 120px;
    left: -100px;
    height: 110%;
  }

  @include media(mobile-md) {
    top: 90px;
    left: -120px;
  }

  @include media(mobile-sm) {
    //top: 90px;
    left: -160px;
  }
}

.person-training-annotations {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.person-training {
  height: 100%;
  display: block;

  @include media(mobile) {
    filter: brightness(0.75);
  }
}

.yoga-recommendations-note,
.yoga-positions-note {
  position: absolute;
  line-height: 1;
  max-width: 150px;

  @include media(tablet) {
    font-size: 17px;
    max-width: 130px;
  }

  @include media(mobile) {
    font-size: 16px;
    max-width: 120px;
    line-height: 1.25;
  }
}

.yoga-recommendations-note {
  bottom: 340px;
  left: 480px;

  @include media(desktop) {
    bottom: 260px;
    left: 370px;
  }

  @include media(tablet) {
    bottom: 200px;
    left: 290px;
  }

  @include media(mobile) {
    bottom: 110px;
    left: 210px;
  }

  @include media(mobile-sm) {
    bottom: 90px;
    left: 190px;
  }
}

.yoga-positions-note {
  bottom: 520px;
  left: 690px;

  @include media(desktop) {
    bottom: 420px;
    left: 552px;
  }

  @include media(tablet) {
    bottom: 310px;
    left: 430px;
  }

  @include media(mobile) {
    bottom: 240px;
    left: 250px;
  }

  @include media(mobile-sm) {
    bottom: 220px;
    left: 230px;
  }
}

.arrow,
.arrow-with-angle,
.arrow-mobile,
.arrow-with-angle-mobile {
  position: absolute;
}

.arrow {
  bottom: 395px;
  left: 450px;
  width: 125px;

  @include media(desktop) {
    bottom: 310px;
    left: 360px;
    width: 95px;
  }

  @include media(tablet) {
    bottom: 230px;
    left: 265px;
    width: 85px;
  }

  @include media(mobile) {
    display: none;
  }
}

.arrow-with-angle {
  bottom: 610px;
  left: 690px;
  width: 185px;

  @include media(desktop) {
    bottom: 500px;
    left: 550px;
    width: 125px;
  }

  @include media(tablet) {
    bottom: 380px;
    left: 430px;
    width: 105px;
  }

  @include media(mobile) {
    display: none;
  }
}

.arrow-mobile {
  display: none;
  bottom: 140px;
  left: 180px;
  width: 55px;

  @include media(mobile) {
    display: block;
  }

  @include media(mobile-sm) {
    bottom: 125px;
    left: 165px;
  }
}

.arrow-with-angle-mobile {
  display: none;
  bottom: 315px;
  left: 250px;
  width: 70px;

  @include media(mobile) {
    display: block;
  }

  @include media(mobile-sm) {
    bottom: 290px;
    left: 230px;
    width: 60px;
  }
}
