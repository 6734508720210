@import 'common/index';

.section.section {
  height: 0;
  padding-bottom: 1180px;

  background: linear-gradient(to bottom, transparent 60%, #000 80%);

  @include media(desktop-max) {
    min-height: 1100px;
    padding-bottom: 76.7368%;
  }

  @include media(tablet) {
    min-height: 940px;
  }

  @include media(mobile) {
    min-height: 770px;
    padding-bottom: 770px;
  }

  .stage {
    background-image: url('./background.png');

    background-position: center -278px;

    @include media(tablet) {
      background-position-y: -200px;
      background-size: auto 2034px;
    }
    @include media(mobile) {
      background-position-y: -111px;
      background-size: auto 1441px;
    }
    @include media(mobile-md) {
      background-size: auto 1253px;
      background-position-y: -97px;
    }
    @include media(mobile-sm) {
      background-size: auto 1003px;
      background-position-y: -58px;
    }
  }

  .box-top-image {
    top: 83px;

    @include media(tablet) {
      height: 20px;
      top: 90px;
    }

    @include media(mobile) {
      top: 90px;
    }

    @include media(mobile-md) {
      height: 12px;
      top: 82px;
    }

    @include media(mobile-sm) {
      height: 10px;
      top: 85px;
    }
  }

  .foreground-layer-container {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  .foreground-layer {
    margin-bottom: 125px;

    @include media(desktop) {
      margin-bottom: 125px;
    }
    @include media(tablet) {
      margin-bottom: 125px;
    }
    @include media(mobile) {
      margin-bottom: 35px;
    }
    @include media(mobile-md) {
      margin-bottom: 60px;
    }
    @include media(mobile-sm) {
      margin-bottom: 45px;
    }
  }

  .title {
    margin-bottom: 10px;
  }

  .pros-list {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 670px;

    @include media(mobile) {
      margin-top: -5px;
    }

    @include media(mobile-md) {
      margin-top: 5px;
    }
  }

  .pros-list-item {
    flex: 0 0 50%;
    list-style: none;
    margin: 0 0 35px;
    padding: 0 10px 0 0;

    &:nth-child(even) {
      .pros-list-item-description {
        margin-left: 35px;
      }
    }

    @include media(tablet) {
      margin-bottom: 0;
    }

    @include media(mobile) {
      &:nth-child(even) {
        padding: 0 0 0 10px;

        .pros-list-item-description {
          margin-left: 0;
        }
      }
    }
  }

  .pros-list-item-title {
    height: 125px;
    display: flex;

    @include media(mobile) {
      height: 65px;
      padding-right: 45px;
    }
  }

  .pros-list-item-image {
    display: block;
    margin-top: auto;

    @include media(mobile) {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .pros-list-item-description {
    font-size: 20px;
    line-height: 1.3;
    margin-top: 28px;
    margin-left: 10px;
    max-width: 290px;

    @include media(mobile) {
      font-size: 16px;
      line-height: 1.25;
      margin-left: 0;
      margin-top: 12px;
    }
  }

  .annotation {
    left: 50%;
    top: 183px;
    position: absolute;
    z-index: 1;
    padding-top: 55px;
    width: 50%;
    max-width: 700px;

    @include media(tablet) {
      padding-top: 0;
    }

    @include media(mobile) {
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 135px;
      width: 50%;
      max-width: 700px;
    }

    @include media(mobile-md) {
      top: 115px;
      width: 320px;
    }

    @include media(mobile-sm) {
      top: 120px;
      width: 210px;
    }
  }

  .annotation-line {
    left: 8px;
    top: 0;
    width: 35.5%;
    position: absolute;

    @include media(tablet) {
      width: 25%;
    }

    @include media(mobile) {
      display: none;
    }
  }

  .annotation-speaker {
    position: relative;
    display: block;
    margin-left: 42%;

    @include media(tablet) {
      width: 25px;
      margin-left: 32%;
    }

    @include media(mobile) {
      margin: 0 auto;
    }
  }

  .annotation-description {
    margin-top: 15px;
    max-width: 37%;
    margin-left: 42%;

    @include media(tablet) {
      margin-left: 32%;
      max-width: 50%;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 1.25;
      text-align: center;
      max-width: 100%;
      margin: 10px auto 0;
    }
  }

  .lamp {
    pointer-events: none;
    background: url('./lamp.png') no-repeat center bottom / contain;
    position: absolute;
    z-index: 2;
    bottom: 20%;
    right: 50%;
    margin-right: 120px;
    width: 60%;
    padding-bottom: 60%;

    @include media(tablet) {
      margin-right: 50px;
      width: 65%;
      padding-bottom: 65%;
      bottom: 20%;
    }
    @include media(mobile) {
      max-width: 500px;
      margin-right: 0;
      width: 103%;
      padding-bottom: 103%;
      bottom: 20%;
    }
    @include media(mobile-md) {
      margin-right: -66px;
      width: 93%;
      padding-bottom: 93%;
      bottom: 33%;
    }
  }
}
