@import 'common/index';

.root {
  position: relative;
}

.stage {
  background-image: url('./background.png');

  @include media(mobile-md) {
    background-image: url('./background-mobile-md.png');
  }
}

.frame {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 4px;
  margin: 0 auto;
  object-fit: cover;
  bottom: 465px;
  height: 478px;
  width: 836px;

  @include media(desktop) {
    bottom: 244px;
  }

  @include media(tablet) {
    bottom: 170px;
    height: 370px;
    width: 649px;
  }

  @include media(mobile) {
    height: 261px;
    width: 459px;
    right: 2px;
    bottom: 189px;
  }
  @include media(mobile-md) {
    height: 227px;
    width: 398px;
    bottom: 238px;
  }
  @include media(mobile-sm) {
    height: 182px;
    width: 318px;
    bottom: 206px;
    right: 1px;
  }
}

.wolf {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 10px;
  margin: 0 auto;
  object-fit: contain;
  height: 170px;
  width: 192px;
  top: 150px;

  @include media(tablet) {
    top: auto;
    bottom: 370px;
    height: 150px;
    width: 158px;
  }
  @include media(mobile) {
    bottom: 340px;
    height: 90px;
    width: 100px;
  }
  @include media(mobile-md) {
    bottom: 364px;
    height: 80px;
    width: 80px;
  }
  @include media(mobile-sm) {
    bottom: 306px;
    height: 70px;
    width: 70px;
  }
}
