@import 'common/index';

.root {
  position: relative;
}

.spacer-top {
  height: 120vh;
  background: linear-gradient(to bottom, #000 90%, transparent 100%);
}

.spacer-bottom {
  height: 80vh;
}

.container {
  position: relative;
}

.title {
  @include grid();
  margin-bottom: 25px;
}

.title-text {
  @include grid-col(12);
  @include font-display();
  @include font-weight(500);
  font-size: 130px;
  line-height: 0.8;
  margin: 0;

  @include grid-col-mobile(12);

  @include media(mobile) {
    font-size: 42px;
    line-height: 0.95;
    max-width: 300px;
  }
}

.subtitle {
  @include grid();
  margin: 35px auto;
  flex-wrap: nowrap;
  align-items: center;

  @include media(mobile) {
    margin-top: 15px;
    margin-bottom: 25px;
  }
}

.subtitle-text {
  margin: 0 25px 0 0;

  @include grid-col-mobile(6);
  @include media(mobile) {
    margin-right: 0;
  }
}

.subtitle-text-value {
  margin: 0;
  font-size: 37px;
  line-height: 1.15;

  @include media(mobile) {
    max-width: none;
    font-size: 16px;
    line-height: 1.25;
  }
}

.benefits {
  list-style: none;
  padding: 0;
  margin: 0;
  @include grid();

  @include media(mobile) {
    display: flex;
  }
}

.benefit {
  position: relative;
  @include grid-col(4);
  @include grid-col-mobile(12);
  font-size: 20px;
  line-height: 1.3;

  &:not(:last-of-type) {
    padding-right: 25px;
  }

  @include media(mobile) {
    &:not(:last-of-type) {
      padding-right: 0;
    }

    & + & {
      margin-left: 20px;
    }

    max-width: 39vw;
    font-size: 16px;
    line-height: 1.25;
  }
}

.benefit-icon {
  display: block;
  width: 52px;
  height: 52px;

  margin-bottom: 9px;

  @include media(mobile) {
    width: 40px;
    height: 40px;
  }
}

.benefit-header {
  @include font-weight(500);
  display: block;
  margin: 6px 0 9px;
  @include font-weight(600);
  line-height: 1.34;

  @include media(mobile) {
    margin: 0 0 4px;
  }
}
