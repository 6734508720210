@import 'common/index';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  min-width: breakpoint(mobile-min);
}

.canvas {
  position: relative;
  z-index: 1;
}

.ray-holder.ray-holder {
  position: absolute;
  top: 0;
  height: 100%;
  margin: 0 auto;
  right: 0;
  left: 0;
  bottom: 0;
  @include ray-width();
}

.hand-wrap {
  position: absolute;
  left: 50%;
  width: 50%;
  z-index: 1;
  transform: translate(100%, -100%);
  opacity: 0;
  top: 50%;

  @include media(mobile) {
    top: 60%;
  }
}

.hand {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
