@import 'common/index';

.root {
  transform-origin: center bottom;
  transform: translate(0, 0);
  position: relative;

  &:not(.custom-height) {
    height: 1056px;

    @include media(desktop) {
      height: 836px;
    }

    @include media(tablet) {
      height: 650px;
    }

    @include media(mobile) {
      height: 560px;
    }

    @include media(mobile-sm) {
      height: 520px;
    }
  }
}

.foreground-layer-container {
  height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.foreground-layer {
  position: relative;
  z-index: 3;
  flex: 1 1 auto;
  margin-bottom: 125px;

  @include ray-size-content();

  @include media(desktop) {
    margin-bottom: 75px;
  }

  @include media(tablet) {
    margin-bottom: 65px;
  }
  @include media(mobile) {
    margin-bottom: 85px;
  }
  @include media(mobile-md) {
    margin-bottom: 135px;
  }
  @include media(mobile-sm) {
    margin-bottom: 55px;
  }
}

.subtitle {
  margin: 0;
  max-width: 500px;

  @include ray-size-content-subtitle();
}

.title {
  @include font-weight(500);
  @include font-display();
  margin: 0 0 20px;

  @include ray-size-content-title();
}

.background-layer {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}

.stage-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}

.stage {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;

  @at-root .root:not(.custom-height) & {
    background-position: center -234px;

    @include media(tablet) {
      background-position-y: -160px;
      background-size: auto 1062px;
    }
    @include media(mobile) {
      background-position-y: -80px;
      background-size: auto 750px;
    }
    @include media(mobile-md) {
      background-size: auto 652px;
      background-position-y: -70px;
    }
    @include media(mobile-sm) {
      background-size: auto 522px;
      background-position-y: -1px;
    }
  }
}

.box-top-image {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: transparent no-repeat url('../../../common/assets/images/top-tv.png') center center / contain;
  height: 40px;

  @at-root .root:not(.custom-height) & {
    top: 75px;

    @include media(tablet) {
      height: 20px;
      top: 90px;
    }

    @include media(mobile) {
      top: 90px;
    }

    @include media(mobile-md) {
      height: 12px;
      top: 82px;
    }

    @include media(mobile-sm) {
      height: 10px;
      top: 121px;
    }
  }
}

.overlay {
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  background: color('black');
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
