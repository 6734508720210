@import 'common/index';

.root {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  will-change: opacity;
}

@keyframes loader {
  0%,
  88%,
  100% {
    opacity: 0;
  }

  0% {
    transform: translateY(-6vmin);
  }

  33% {
    opacity: 1;
  }

  33%,
  88% {
    transform: translateY(3vmin);
  }
}

.loader {
  display: block;
  position: relative;
  width: 50%;
}

.loader::before,
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  height: 3vmin;
  width: 0.4vmin;
  background-color: white;
  animation: loader 0.6s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

.loader::before {
  left: calc(50% - 1vmin);
  top: calc(50% - 3vmin);
}

.loader::after {
  left: calc(50% + 1vmin);
  top: calc(50% - 1vmin);
  animation-delay: 0.2s;
  will-change: opacity, transform;
}
