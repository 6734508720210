@import 'common/index';

.root {
  text-decoration: none;
  border-radius: 200px;
  color: color('white');
  background: color('blue');
  @include font-display;
  @include font-weight(500);
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.01em;
  display: inline-flex;
  align-items: center;
  padding: 14px 50px;
  min-height: 60px;
  @include transition(background color);

  &:hover {
    background: color('blue-darker');
  }

  @include media(mobile) {
    font-size: 15px;
    letter-spacing: -0.02em;
    padding: 10px 44px;
    min-height: 42px;
  }
}
