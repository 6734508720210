@import 'common/index';

.root {
  position: relative;
}

.spacer-top {
  height: 120vh;
}

.spacer-bottom {
  height: 80vh;
}

.container {
  position: relative;
}

.title {
  @include grid();
  margin-bottom: 25px;
}

.title-text {
  @include grid-col(12);
  @include font-display();
  @include font-weight(500);
  font-size: 130px;
  line-height: 0.8;
  margin: 0;

  @include grid-col-mobile(12);

  @include media(mobile) {
    max-width: 250px;

    font-size: 42px;
    line-height: 0.95;
  }
}

.subtitle {
  @include grid();
  margin: 35px auto;
  flex-wrap: nowrap;
  align-items: center;

  @include media(mobile) {
    margin-top: 15px;
    margin-bottom: 25px;
    align-items: flex-start;
  }
}

.subtitle-text {
  margin: 0 25px 0 0;
  @include grid-col-mobile(6);
}

.subtitle-text-value {
  margin: 0;
  font-size: 37px;
  line-height: 1.15;

  @include media(mobile) {
    max-width: none;
    font-size: 22px;
    line-height: 1.18;
  }
}

.benefits {
  list-style: none;
  padding: 0;
  margin: 0;
  @include grid();
}

.benefit {
  position: relative;
  width: 33.33333%;
  // max-width: 30%;
  list-style: none;

  @include grid-col(4);
  @include grid-col-mobile(12);
  font-size: 20px;
  line-height: 1.3;

  &:not(:last-of-type) {
    padding-right: 33px;
  }

  @include media(mobile) {
    max-width: 28%;
    font-size: 16px;
    line-height: 1.25;
  }

  @include media(mobile-sm) {
    max-width: 35%;

    &:first-child {
      margin-right: 75px;
    }
  }
}

.benefit-icon {
  display: block;
  width: 52px;
  height: 52px;

  margin-bottom: 9px;

  @include media(mobile) {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }
}
