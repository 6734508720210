@import 'common/index';

.root {
  background: color('black');
  position: relative;
  padding-bottom: 72.5%;
  min-height: 840px;
  overflow: hidden;

  @include media(mobile) {
    min-height: 598px;
  }

  &[style*='opacity: 0'] {
    will-change: opacity, transform;
  }
}

.background-layer {
  background: url('./background.jpg') no-repeat center top / cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 79.79%;
  z-index: 1;

  @include media(mobile-md) {
    height: 63%;
    background: url('./background-mobile-md.jpg') no-repeat center top / auto 100%;
  }
}

.person-card {
  position: absolute;
  left: 25px;
  top: 45px;
  max-width: 16.7vw;

  @include media(mobile) {
    display: none;
  }
}

.user-pic {
  border-radius: 50%;
  width: 3.8vw;
  height: 3.8vw;
  position: absolute;
  bottom: 35px;
  right: 85px;

  @include media(tablet) {
    width: 4.4vw;
    height: 4.4vw;
    bottom: 0;
    right: 25px;
  }

  @include media(mobile) {
    display: none;
  }
}

.call-side-controls {
  position: absolute;
  right: 25px;
  top: 100px;
  bottom: 0;
  margin: auto 0;
  max-width: 4.4vw;

  @include media(mobile) {
    right: 8px;
    top: 175px;
    bottom: auto;
    margin: 0;
    max-width: 28px;
  }
}

.foreground-layer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 10%;

  @include media(desktop) {
    padding-bottom: 84px;
  }

  @include media(mobile) {
    padding-bottom: 62px;
  }
}

.container {
  position: relative;
  text-align: center;
  max-width: $grid-desktop-width;
  margin: 0 auto;

  @include media(mobile) {
    max-width: 360px;
  }
}

.title {
  position: relative;
  z-index: 3;
  @include font-weight(500);
  @include font-display();
  font-size: 70px;
  line-height: 0.92;
  margin: 0 0 14px;

  @include media(mobile) {
    font-size: 42px;
    line-height: 95%;
  }
}

.subtitle {
  position: relative;
  z-index: 3;
  margin: 0;
  font-size: 20px;
  line-height: 1.3;

  @include media(mobile) {
    font-size: 16px;
  }
}

.call-bottom-controls {
  margin: 0 auto 12%;
  display: block;
  max-width: 23.98vw;
  position: relative;
  z-index: 1;

  @include media(desktop) {
    margin-bottom: 82px;
  }

  @include media(tablet) {
    margin-bottom: 40px;
    max-width: 155px;
  }
}

.customer {
  pointer-events: none;
  background: url('./customer.png') no-repeat center bottom / auto 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 17%;
  height: 93.8%;
  width: 100%;
  z-index: 2;

  @include media(desktop) {
    top: 185px;
  }

  @include media(mobile) {
    top: 120px;
    height: 86.2%;
    background-position: 42% bottom;
  }

  @include media(mobile-md) {
    background-image: url('./customer-mobile-md.png');
    background-position: 45% bottom;
  }
}
