@import 'common/index';

.root {
  position: relative;
}

.spacer-top {
  background: #000;
  height: 100vh;
}

.top-content {
  display: block;
}

.content-container {
  height: 790px;
  position: relative;
  padding-bottom: 10vh;

  @include media(mobile-sm) {
    height: 410px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    top: -100%;

    background: linear-gradient(180deg, transparent 35%, #000 75%);
  }
}

.container {
  position: relative;
  height: 100%;
}

.intro-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60vh;
}

.intro-title {
  @include font-display();
  @include font-weight(500);
  font-size: 130px;
  line-height: 0.8;
  text-align: center;

  @include media(mobile) {
    font-size: 96px;
  }

  @include media(mobile-sm) {
    font-size: 42px;
    line-height: 0.95;
  }
}

.title {
  position: relative;
  top: -285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;

  @include media(mobile-sm) {
    top: -90px;
  }
}

.title-text {
  @include grid-col(12);
  @include font-display();
  @include font-weight(500);
  font-size: 150px;
  line-height: 0.8;
  margin: 0;
  margin-top: -11px;
  padding: 16px;
  box-sizing: border-box;

  @supports (-webkit-background-clip: text) {
    background: linear-gradient(90deg, #72f0d9 0%, #9ff554 100%);

    // stylelint-disable-next-line
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include grid-col-mobile(12);

  &::first-letter {
    margin-left: -1.4ch;
  }

  @include media(mobile) {
    font-size: 96px;
  }

  @include media(mobile-sm) {
    padding: 0;
    font-size: 42px;
    line-height: 0.95;
  }
}

.hands {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;

  @include media(mobile-sm) {
    display: block;
    position: relative;
  }
}

.hand-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.left-hand-container,
.central-hand-container,
.right-hand-container {
  height: 100%;
}

.left-hand-container {
  position: relative;
  width: 36.5%;

  @include media(mobile-sm) {
    position: absolute;
    width: 53%;
    top: -115px;
    left: 0px;
  }
}

.central-hand-container {
  position: relative;
  width: 30%;

  @include media(mobile-sm) {
    position: absolute;

    top: 13%;
    left: 35%;
    margin-right: 0;
    width: 40%;
  }
}

.right-hand-container {
  position: relative;
  width: 33.5%;

  @include media(mobile-sm) {
    position: absolute;
    width: 45%;
    right: 0px;
    top: -55px;
  }
}
