@function color($color-key) {
  $quoted-color-key: quote($color-key);

  @if map-has-key($colors, $quoted-color-key) != true {
    @error 'There is no such color key "#{$quoted-color-key}" in the $colors map. Available keys are: [ #{map-keys($colors)} ].';
  }

  @return map-get($colors, $quoted-color-key);
}

@function breakpoint($breakpoint-key) {
  $quoted-color-key: quote($breakpoint-key);

  @if map-has-key($breakpoints, $quoted-color-key) != true {
    @error 'There is no such breakpoint key "#{$quoted-color-key}" in the $breakpoints map. Available keys are: [ #{map-keys($breakpoints)} ].';
  }

  @return map-get($breakpoints, $quoted-color-key);
}

@function get-black-gradient($direction: 0deg) {
  @return linear-gradient(
    $direction,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.0086472) 6.94%,
    rgba(0, 0, 0, 0.03551) 13.88%,
    rgba(0, 0, 0, 0.0816599) 20.81%,
    rgba(0, 0, 0, 0.147411) 27.75%,
    rgba(0, 0, 0, 0.231775) 34.69%,
    rgba(0, 0, 0, 0.331884) 41.63%,
    rgba(0, 0, 0, 0.442691) 48.56%,
    rgba(0, 0, 0, 0.557309) 55.5%,
    rgba(0, 0, 0, 0.668116) 62.44%,
    rgba(0, 0, 0, 0.768225) 69.38%,
    rgba(0, 0, 0, 0.852589) 76.31%,
    rgba(0, 0, 0, 0.91834) 83.25%,
    rgba(0, 0, 0, 0.96449) 90.19%,
    rgba(0, 0, 0, 0.991353) 97.13%,
    #000 104.07%
  );
}
